// directives
export * from './directives/tin-alert.component';
export * from './directives/image-loader.directive';

//export models and conts
export * from './_consts/constants';
export * from './_models/project-details.model';
export * from './_models/page2.model';
export * from './_models/results.model';


export * from './_models/api-result.model';
export * from './_pipes/keys.pipe';
export * from './_pipes/truncate.pipe';

export * from './_models/alert';
export * from './_models/api-response.model';

