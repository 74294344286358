
export class Page2Model {
  totalSalary: string;
  rent: string;
  power: string;
  group1UsableTiB: string;
  v1FSInitialRaw: string;
 

}
