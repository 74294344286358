import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseComponent } from '../../core/_base/base-component';
import * as _ from 'lodash';
import { AppState } from '../../core/reducers';
import { Store } from '@ngrx/store';
declare var $: any;
import { environment } from '../../../environments/environment';
import { ProjectDetailsService } from 'src/app/core/spectrum/_services';
import { SettingsService } from 'src/app/core/spectrum/_services/settings.service';
import { MatDialog } from '@angular/material';
import { DownloadComponent } from '../download/download.component';
import { UploadComponent } from '../upload/upload.component';

@Component({
    selector: 'header-block',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderBlock extends BaseComponent implements OnInit {
    @Input() loader: { isLoading: false};
    apiEndPoint: string;// = environment.SPECTRUM_API;
    key = environment.API_KEY as unknown as {value: ''};

    constructor(
        toaster: ToastrService,
        spinner: NgxSpinnerService,
        cdr: ChangeDetectorRef,
        private settingsService: SettingsService,
        public router: Router,
        public pdService: ProjectDetailsService) {

        super(toaster, spinner, cdr);
          this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
    }

    ngOnInit() { }
  }

