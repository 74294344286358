export class ApiResponse {
    success: boolean;
    data: any;
    message: string;
}

export class UserSignupApiResponse {
    access_token: string;
    expires_in: 3600;
    token_type: string;
    error: string;
    User: any;
}
