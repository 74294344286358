import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanDeactivate,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent } from 'ngx-bootstrap';
import { ComponentCanDeactivate } from '..';

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate> {
  bsModalRef: BsModalRef;
  decision: boolean;
  constructor(private bsModalService: BsModalService, private router: Router) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.canDeactivate()) {
      return true;
    }
    const initialState = {
      title: 'Alert!',
      message: 'You have unsaved changes. Are you sure you want to navigate?'
    };
    this.bsModalRef = this.bsModalService.show(AlertComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    return this.bsModalService.onHide.asObservable();
  }
}
