import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AppState } from '../../core/reducers';
import { Store } from '@ngrx/store';

@Component({
    selector: 'footer-block',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterBlock implements OnInit{
   
    constructor(private store: Store<AppState>,
        private route: ActivatedRoute,
        public router: Router) {
       
    }

    ngOnInit() {

    } 
}
