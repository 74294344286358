import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { AngularReactDatesModule } from "angular-react-dates";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from "ngx-pagination";
import { RouterModule } from "@angular/router";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import * as _shared from './index';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { TruncatePipe } from '../core/spectrum';
import { TooltipModule } from 'ngx-bootstrap';
import { MatButtonModule, MatDialogModule, MatSelectModule, MatFormField, MatFormFieldModule } from '@angular/material';

const partialComponents = [
  _shared.FooterBlock,
  _shared.HeaderBlock,
  _shared.DownloadComponent,
  _shared.UploadComponent,
  _shared.ReleaseNoteComponent,
  _shared.Page2Component,

  TruncatePipe
];

@NgModule({
  declarations: partialComponents,
  exports: partialComponents,
  imports: [
    CommonModule,
    RouterModule,
    AngularReactDatesModule.forRoot(),
    PerfectScrollbarModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    ToastrService
  ],
  entryComponents: [_shared.DownloadComponent, _shared.UploadComponent, _shared.ReleaseNoteComponent,  _shared.Page2Component,]
})
export class PartialsModule {
}
