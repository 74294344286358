
export class ProjectDetailsModel {
  currencySymbol: string;
  companyName: string;
  country: string;
  state: string;
  setting: string;
  industry: string;
  analysisPeriod: string;
 
  group1Usable: string;
  v1FSCompressionRate: string;
  growth1: string;
  v1HwDiscount: string;
  v1Flash: string;
  v1FSDrive1Raid: string;
  v1FSDrive1Rank: string;
  v1OptimalDisk1: string;
  v1OptimalDisk2: string;
  v1AlternativeDisk: string;
  v1InitHwCost: string;
  v1TotalHwCost: string;
  v1EstTco: string;

  

  countryRange: string[];
  stateRange: string[] = [];
  depRange: string[] = [];
  percentRange: string [] = [];
  v1FSFlashChoices: string [] = [];
  v1FSDrive1AllowedRaidRange: string [] = [];
  v1FSDrive1AllowedParity: string [] = [];

}

