import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[imageLoader]'
})
export class ImageLoader {
    @Input() imageLoader;

    constructor(private el: ElementRef) {
        this.el = el.nativeElement;
        //@ts-ignore
        this.el.style.backgroundImage = "url(http://smallenvelop.com/demo/image-loading/spinner.gif)";
    }

    ngOnInit() {
        let image = new Image();
        image.addEventListener('load', () => {
            //@ts-ignore
            this.el.style.backgroundImage = `url(${this.imageLoader})`;
        });
        image.src = this.imageLoader;
    }
}