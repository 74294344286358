import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import * as _views from './views/index';
import { AppResolver } from './core/route-resolvers/app.resolver';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo:"details"
  },
  {
    path: 'details',
    loadChildren: () => import('./views/project-details/project-details.module').then(m => m.ProjectDetailsModule),
    resolve: {
      key: AppResolver
    }
  },
  {
    path: 'results',
    loadChildren: () => import('./views/results/results.module').then(m => m.ResultsModule),
    resolve: {
      key: AppResolver
    }
  },
  {
    path: 'insight',
    loadChildren: () => import('./views/insight/insight.module').then(m => m.InsightModule),
    resolve: {
      key: AppResolver
    }
  },
  { path: "**", redirectTo: "details" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [

  ]
})
export class PagesRoutingModule {
}
