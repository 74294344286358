import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProjectDetailsService } from 'src/app/core/spectrum/_services';
import { SettingsService } from 'src/app/core/spectrum/_services/settings.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  fileFormat = ".FlashProject";
  apiEndPoint: string;
  key = environment.API_KEY as unknown as {value: ''};

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    public pdService: ProjectDetailsService,
    public dialogRef: MatDialogRef<UploadComponent>
    ) { 
      this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
    }

  ngOnInit() {
  }

  handleFileInput(files: FileList) {
    // handle uploaded JSON files
    // if user uploads more than 1 file, only inspect the first
    if (files && files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsText(files[0], 'UTF-8');
      fileReader.onload = () => {        
        // file uploaded successfully
        let fileData = fileReader.result as string;
        if (this.fileFormat == ".FlashProject") {
          this._uploadProject(fileData).then(() => {});
        } else {
          this._uploadStorm(fileData);
        }        
      };

      fileReader.onerror = (error) => {
        // error uploading file
        alert(error);
      };
    }
  }

  async _uploadProject(result: string) {
    const output = JSON.parse(result);
    const obs = [];
    let compatFile = false;

    if (output) {
      // parse out stringified JSON, keyed by tab name
      const projDetails = output.projDetails ? output.projDetails : null;
      

      // push any found tab data into the observable array
      if (projDetails) {
        //obs.push(this.pdService.saveModel(projDetails));
        await this.pdService.saveModel(projDetails).toPromise();
        compatFile = true;
      } 

    }

    if (compatFile) {
      this.closeModal();
    } else {
      alert('Incompatible project file. Data is missing.');
    }

    // if (obs.length > 0) {
    //   // execute observables
    //   forkJoin(obs).subscribe(() => {
    //     // currently active tab component will be listening
    //     // for this event and refresh its form data
    //     this.closeModal();
    //   });
    // } else {
    //   alert('Incompatible project file. Data is missing.');
    // }
  }

  _uploadStorm(result: string) {
    const data = JSON.parse(result);
    this.pdService.saveStormFile(data)
    .subscribe(() => {
      this.closeModal();
    });
  }

  closeModal() {
    this.dialogRef.close(true);
  }
}
