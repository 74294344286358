import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Page2Service } from '../../core/spectrum/_services';
import { Page2Model } from '../../core/spectrum';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'page2',
  templateUrl: './page2.component.html',
  styleUrls: ['./page2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Page2Component implements OnInit {
  toggle: boolean = true;
  model: Page2Model;
  page2Form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private sbService: Page2Service) {
  }

  ngOnInit() {
    this.spinner.show();
    this.sbService.getPage2Data().subscribe((data) => {
      this.model = data;
      this.cdr.markForCheck();
      this.initForm();
    },
      (e) => {
        this.spinner.hide();
        console.log(e);
      },
      () => {
        this.spinner.hide();
      });
  }

  @HostListener('window:loadForms', ['$event,target'])
  onloadForms(e) {
    this.ngOnInit();
  }

  initForm() {
    this.page2Form = this.fb.group({
      totalSalary: [this.model.totalSalary],
      rent: [this.model.rent],
      power: [this.model.power],
      group1UsableTiB: [this.model.group1UsableTiB],
      v1FSInitialRaw: [this.model.v1FSInitialRaw],     
    });
  }

  updateGroups() {
    this.spinner.show();
    this.sbService.updateGroups(this.prepareForm())
      .subscribe((res) => {
        if (res.success) {
          this.ngOnInit();
        }

      }, (e) => { this.spinner.hide(); });
  }

  prepareForm(): Page2Model {
    var m = new Page2Model();
   

      return m;
  }


  detectChanges() {
    try {
      this.cdr.detectChanges();
    } catch (e) {

    }
  }

}
