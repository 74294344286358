import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import 'react-dates/lib/css/_datepicker.css';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingModule, BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { CoreModule } from './core/core.module';

import { ErrorInterceptorProvider, AuthGuard } from './core/auth';
import { PagesRoutingModule } from './pages-routing.module';
import { PartialsModule } from './partials/partials.module';
import * as _views from './views';
import { StoreModule } from '@ngrx/store';
import { AlertService, ErrorHandlerService, LocalStorageService, ProjectDetailsService, Page2Service, ResultsService, ReportService } from './core/spectrum/_services';
import { AppResolver } from './core/route-resolvers/app.resolver';
import { AppMaterialModule } from './app-material.module';
import { InsightModule } from './views/insight/insight.module';
import { ReportComponent } from './views/insight/report/report.component';
import { SettingsHttpService } from './core/spectrum/_services/settings.http.service';
import { SettingsService } from './core/spectrum/_services/settings.service';
import { ProjectDetailsComponent } from './views';
import { DownloadComponent } from './partials';

export function app_Init(settingHttpService: SettingsHttpService) {
  return () => settingHttpService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    CoreModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    MomentModule.forRoot(),
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    PartialsModule,
    PagesRoutingModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    StoreModule.forRoot({}),

    LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    RatingModule
  ],
  providers: [
    ToastrService,
    Title,
    LocalStorageService,
    SettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: app_Init,
      deps: [SettingsHttpService],
      multi: true
    },
    //{
    //  provide: APP_INITIALIZER,
    //  useFactory: (ds: LocalStorageService) => function () { return ds.loadToken() },
    //  deps: [LocalStorageService],
    //  multi: true
    //},
    AppResolver,
    ErrorHandlerService,
    AlertService,
    ErrorInterceptorProvider,
    AuthGuard,
    ProjectDetailsService,
    Page2Service,
    ResultsService,
    ReportService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
