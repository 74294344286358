import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class TinErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = this.authenticationService.currentUserValue;

        //if (currentUser && currentUser.access_token && req.url && !(req.url.indexOf('authenticate') === -1 || req.url.indexOf('ajax') === -1 || req.url && req.url.indexOf('MGrqispi.dll') == -1)) {
        if (currentUser && currentUser.access_token && req.url && (req.url.indexOf('MGrqispi.dll') == -1)) {
            req = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${currentUser.access_token}`
                }
            });
        }

        if (req.headers.get('Content-Type') == null && req.url && req.url.indexOf('MGrqispi.dll') == -1) {
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            });
        }

        return next.handle(
            req.clone({
                headers: req.headers
            })
        ).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        return throwError(error.statusText);
                    }
                    const applicationError = error.headers.get('Application-Error');
                    if (applicationError) {
                        console.error(applicationError);
                        return throwError(applicationError);
                    }
                    const serverError = error.error;
                    let modalStateErrors = '';
                    if (serverError && typeof serverError === 'object') {
                        for (const key in serverError) {
                            if (serverError[key]) {
                                modalStateErrors += serverError[key] + '\n';
                            }
                        }
                    }
                    return throwError(modalStateErrors || serverError || 'Server Error');
                }
            })
        );
    }
}

export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TinErrorInterceptor,
    multi: true
};
