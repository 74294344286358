import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment';
import { UserSignupApiResponse } from '../../spectrum';
import { SFUserInfo, SFToken } from '../_models/tin-user.model';
import { ErrorHandlerService, LocalStorageService } from '../../spectrum/_services';
import { AppConfig } from '../../_config/app-portal.config';
import { SignupUserModel } from '../_models/signup-user.model';
import { SettingsService } from '../../spectrum/_services/settings.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public currentUser: Observable<UserSignupApiResponse>;
  public currentUserInfo: Observable<SFUserInfo>;
  apiEndpoint: string; // = environment.SPECTRUM_API;
  private currentUserSubject: BehaviorSubject<UserSignupApiResponse>;
  private currentUserInfoSubject: BehaviorSubject<SFUserInfo>;

  constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService, 
    private router: Router, 
    private localStorageService: LocalStorageService,
    private settingsService: SettingsService) {
      this.apiEndpoint = this.settingsService.settings.SPECTRUM_API;
    this.populateUser();
  }

  public get currentUserValue(): UserSignupApiResponse {
    //return this.currentUserSubject.value;
    return null;
  }

  populateUser(): void {
    //const currentUser = this.localStorageService.getFromLocalStorage(AppConfig.USER_TOKEN_KEY);
    //this.currentUserSubject = currentUser ?
    //  new BehaviorSubject<UserSignupApiResponse>(JSON.parse(currentUser)) :
    //  new BehaviorSubject<UserSignupApiResponse>(new UserSignupApiResponse());
    //this.currentUser = this.currentUserSubject.asObservable();

    //const currentUserInfo = this.localStorageService.getFromLocalStorage(AppConfig.USER_INFO_KEY);
    //this.currentUserInfoSubject = currentUserInfo ?
    //  new BehaviorSubject<SFUserInfo>(JSON.parse(currentUserInfo)) :
    //  new BehaviorSubject<SFUserInfo>(new SFUserInfo());
    //this.currentUserInfo = this.currentUserInfoSubject.asObservable();
  }

  login(username: string, password: string) {

    const body = {
      username: username,
      password: password,
      grant_type: 'password',
      scope: 'openid profile email',
      client_id: 'authClient',
      client_secret: 'secret'
    };
    return this.http.post<any>(`${this.apiEndpoint}/api/user/authenticate`, body)
      .pipe(
        retry(1), catchError(this.errorHandler.handleError),
        map((user: UserSignupApiResponse) => {
          if (user.access_token !== '') {
            this.localStorageService.storeOnLocalStorage(JSON.stringify(user), AppConfig.MODULE_ID);
            this.currentUserSubject.next(user);
          }
          return user;
        }));
  }

  signup(signupModel: SignupUserModel) {
    signupModel.isApproved = true;
    signupModel.roles = [{ name: 'Consumer' }];

    return this.http.post<any>(`${this.apiEndpoint}/api/user/createuser`, signupModel)
      .pipe(
        retry(1), catchError(this.errorHandler.handleError),
        map((response) => response));
  }

  authenticate() {
    const tinUser = this.currentUserValue;

    const helper = new JwtHelperService();
    if (tinUser == null || this.readToken() == null || helper.isTokenExpired(this.currentUserValue.access_token)) {
      localStorage.removeItem(AppConfig.MODULE_ID);
      localStorage.removeItem(AppConfig.USER_INFO_KEY);
      this.router.navigate(['/user/login']);
    }
  }

  isAuthenticated(): any {
    const tinUser = this.currentUserValue;
    const helper = new JwtHelperService();
    if (tinUser == null || this.readToken() == null || helper.isTokenExpired(this.currentUserValue.access_token)) {
      return false;
    }
    return true;
  }

  readToken(): SFToken {
    try {
      const helper = new JwtHelperService();
      return helper.decodeToken(this.currentUserValue.access_token) as SFToken;
    } catch (e) {
      return null;
    }
  }

  getCurrentUserInfo(): Observable<SFUserInfo> {
    if (this.currentUserValue && this.currentUserValue.access_token) {
      //if (this.currentUserInfoSubject && this.currentUserInfoSubject.getValue() && this.currentUserInfoSubject.getValue().Email) {
      //    return of(this.currentUserValue.User);
      //}

      return this.http.get<any>(`${this.apiEndpoint}/api/user/getuser`)
        .pipe(
          retry(1), catchError(this.errorHandler.handleError),
          map((user) => {
            user = user.Data;
            this.localStorageService.storeOnLocalStorage(JSON.stringify(user), AppConfig.USER_INFO_KEY);
            this.currentUserInfoSubject.next(user);
            return user;
          }));
    }
    return new Observable<SFUserInfo>();
  }

  saveUserInfo(userInfo: SFUserInfo): any {
    return this.http.post(`${this.apiEndpoint}/api/User/ModifyUser`, userInfo).subscribe((res) => {
      this.refreshUserInfo();
      return res;
    });
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(AppConfig.MODULE_ID);
    localStorage.removeItem(AppConfig.USER_INFO_KEY);
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }

  refreshUserInfo() {
    localStorage.removeItem(AppConfig.USER_INFO_KEY);
    //this.currentUserInfoSubject.next(null);
    this.getCurrentUserInfo();
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(`${this.apiEndpoint}/ajax/user/forgotpassword`, {
      'username': email
    })
      .pipe(
        retry(1), catchError(this.errorHandler.handleError),
        map((resp) => {
          return resp;
        }));
  }

  changePassword(model: any): Observable<any> {
    return this.http.post(`${this.apiEndpoint}/ajax/user/changepassword`, model)
      .pipe(
        retry(1), catchError(this.errorHandler.handleError),
        map((resp) => {
          return resp;
        }));
  }
}
