import { Injectable } from '@angular/core';

import { Resolve } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../spectrum';
import { SettingsService } from '../spectrum/_services/settings.service';

@Injectable()
export class AppResolver implements Resolve<Observable<ApiResponse>> {
  key = environment.API_KEY;
  apiEndPoint: string; // = environment.SPECTRUM_API;
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
    ) {
      this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
    }

  resolve(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/create-module/${this.key}`);
  }
}
