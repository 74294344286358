import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../_models/api-response.model';
import { ResultsModels } from '../_models/results.model';
import { SettingsService } from './settings.service';


@Injectable()
export class ResultsService {
  public apiEndPoint: string; // = environment.SPECTRUM_API;
  key = environment.API_KEY as unknown as { value: '' };
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private settingsService: SettingsService
  ) {
    this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
    this.route.data.subscribe(() => {
    });
  }

  getData(): Observable<ResultsModels> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/results`).pipe(map(s => s.data));
  }

  updateData(model: ResultsModels): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.apiEndPoint}/${this.key}/results`, model);
  }

}
