// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowErrorsComponent } from './_base/show-errors-component.model';
import { ErrorPageComponent } from './_base/error-page/error-page.component';
import { TinAlertComponent, KeysPipe, ImageLoader } from './spectrum';

const exportElements: any = [
    ShowErrorsComponent,
    ErrorPageComponent,
    TinAlertComponent,
    KeysPipe,
    ImageLoader
];

@NgModule({
	imports: [CommonModule],
	declarations: exportElements,
    exports: exportElements,
	providers: []
})
export class CoreModule {
}
