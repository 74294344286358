import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChangeDetectorRef } from '@angular/core';

export class BaseComponent {
    constructor(toaster: ToastrService, spinner: NgxSpinnerService, cdr: ChangeDetectorRef) {
        this.toaster = toaster;
        this.spinner = spinner;
        this.cdr = cdr;
    }
    toaster: ToastrService;
    spinner: NgxSpinnerService;
    cdr: ChangeDetectorRef;

    showSuccess(title: string = 'success', message: string = 'success') {
        this.toaster.success(message, title, {
            timeOut: 6000
        });
    }

    showError(title: string = 'Error', message: string = 'An error occured while processing your request. Please try again later.') {
        this.toaster.error(message, title, {
            timeOut: 6000
        });
    }
    showLoading() {
        //this.spinner.show();
    }
    hideLoading() {
        //this.spinner.hide();
    }

    detectChanges() {
        try {
            this.cdr.detectChanges();
        } catch (e) {

        }
    }
}
