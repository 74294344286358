import {Component} from '@angular/core';
import { fadeAnimation } from './core/animations/fade.animation';
import { setTheme } from 'ngx-bootstrap';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
  styles: [`
          
        `],
    animations: [fadeAnimation]
})
export class AppComponent {
    name = 'Spectrum';
    constructor() {
      setTheme('bs3');
    }

}
