export class TinUser {
    expires_in: number;
    token?: string;
    refresh_token?: string;
    access_token?: string;
    tokey_type?: string;
}

export class CurrentUser {
    email: string;
    given_name: string;
    family_name: string;
    nickname: string;
    name: string;
    picture: string;
}

/*------------------- SF Models ------------------------------------- */
export class SFUserInfo {
    Email: string;
    FirstName: string;
    LastName: string;
    SecretQuestion: string;
    SecretAnswer: string;
    Biography: string;
    City: string;
    Dribble: string;
    Facebook: string;
    HouseNumber: string;
    ImageUrl: string;
    Instagram: string;
    LinkedIn: string;
    Phone: string;
    State: string;
    Street: string;
    Twitter: string;
    Zip: string
}

export class SFToken {
    iss: string;
    aud: string;
    exp: number;
    nbf: number;
    client_id: string;
    scope: string[];
    sub: string;
    auth_time: number;
    idp: string;
    jti: string;
    amr: string[];
}