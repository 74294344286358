import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApiResult } from '../_models/api-result.model';
import { Observable, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ApiResponse } from '../_models/api-response.model';
import { ProjectDetailsModel } from '../_models/project-details.model';
import { SettingsService } from './settings.service';
import { StormFileModel } from '../_models/storm-file.model';

@Injectable()
export class ProjectDetailsService {
  apiEndPoint: string;// = environment.SPECTRUM_API;
  key = environment.API_KEY as unknown as {value: ''};
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private settingsService: SettingsService
  ) {
    this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
    this.route.data.subscribe(data => {});
  }

  getProjectDetailsData(): Observable<ProjectDetailsModel> {
      return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/project-details`).pipe(retry(4), map(s => s.data));
  }

  saveStormFile(data: StormFileModel): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(`${this.apiEndPoint}/${this.key}/load-storm-file`, data);
  }

  saveModel(model: ProjectDetailsModel) {
    return this.http.post<ApiResponse>(`${this.apiEndPoint}/${this.key}/project-details`, model);
  }
  getCurrencySymbol(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/CurrencySymbol`).pipe(map(s => s.data));
  }
  getCompanyName(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/CompanyName`).pipe(map(s => s.data));
  }

  getCountry(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/Country`).pipe(map(s => s.data));
  }

  getState(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/State`).pipe(map(s => s.data));
  }

  getSetting(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/Setting`).pipe(map(s => s.data));
  }

  getIndustry(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/Industry`).pipe(map(s => s.data));
  }

  getAnalysisPeriod(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/AnalysisPeriod`).pipe(map(s => s.data));
  }
  getGrowth1(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/Growth1`).pipe(map(s => s.data));
  }
  getGroup1Usable(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/Group1Usable`).pipe(map(s => s.data));
  }
  getv1FSCompressionRate(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1FSCompressionRate`).pipe(map(s => s.data));
  }
  getv1HwDiscount(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1HwDiscount`).pipe(map(s => s.data));
  }
  getv1Flash(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1Flash`).pipe(map(s => s.data));
  }
  getv1FSDrive1Raid(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1FSDrive1Raid`).pipe(map(s => s.data));
  }
  getv1FSDrive1Rank(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1FSDrive1Rank`).pipe(map(s => s.data));
  }
  getv1OptimalDisk1(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1OptimalDisk1`).pipe(map(s => s.data));
  }
  getv1OptimalDisk2(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1OptimalDisk2`).pipe(map(s => s.data));
  }
  getv1AlternativeDisk(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1AlternativeDisk`).pipe(map(s => s.data));
  }
  getv1InitHwCost(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1InitHwCost`).pipe(map(s => s.data));
  }
  getv1TotalHwCost(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1TotalHwCost`).pipe(map(s => s.data));
  }
  getv1EstTco(): Observable<ApiResult> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/value/v1EstTco`).pipe(map(s => s.data));
  }




  getCountryRange(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/range/CountryRange`).pipe(map(s => s.data));
  }

  getStateRange(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/range/StateRange`).pipe(map(s => s.data));
  }

  getDepRange(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/range/DepRange`).pipe(map(s => s.data));
  }

  getPercentRange(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/range/PercentRange`).pipe(map(s => s.data));
  }

  getv1FSFlashChoices(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/range/v1FSFlashChoices`).pipe(map(s => s.data));
  }

  getv1FSDrive1AllowedRaidRange(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/range/v1FSDrive1AllowedRaidRange`).pipe(map(s => s.data));
  }

  getv1FSDrive1AllowedParity(): Observable<Array<ApiResult>> {
    return this.http.get<ApiResponse>(`${this.apiEndPoint}/${this.key}/range/v1FSDrive1AllowedParity`).pipe(map(s => s.data));
  }


}
