export class ResultsModels {
  totalHardwareSavings: string;
  totalSoftwareSavings: string;
  totalSupportSavings: string;
  totalPersonnelSavings: string;
  totalFacilitiesSavings: string;
  totalServicesSavings: string;
  totalSavings: string;
  v1Flash: string;
  v1BudgetedTco: string;
  v2Flash: string;
  v2BudgetedTco: string;
  
  selectedCostByCategory: string;
  initialByCategory: string;
  v1OnsiteCostbyCategory: string;
  v2OnsiteCostbyCategory: string;
  tcoOverTime: string;
}

export interface JsonString {
  rows: Array<Array<string>>;
}
