import { HttpHeaders } from '@angular/common/http';
export const ConnectConstants = {
  httpErrorResponses: {
    400: 'Your input is not valid',
    401: 'You are not authorized',
    402: 'Payment is required',
    403: 'You are forbidden',
    404: 'Your request is not found',
    405: 'Method not allowed',
    406: 'Server response is not acceptable',
    415: 'UnSupported Media Type'
  },
  ToastrOptions: {
    easeTime: 300,
    timeOut: 3000,
    positionClass: 'toast-top-right',
    tapToDismiss: false,
    closeButton: false,
    enableHtml: true
  },
  defaultImage: 'https://admin.stage-espwebsite.com/images/ImageNotInCatalog110.png',
  absolutePath: 'https://admin.stage-espwebsite.com/',
  mediaPath: 'https://media.asicdn.com/images/jpgt/',
  noImagePath: 'images/ImageNotInCatalog110.png',
  Url_regularexpression: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
  query_regex: '^[0-9]+$',
  Reports: {
    UploadFileMaxSize: 2097152 // 2MB
  }
};

export enum KeyCodes {
  Enter = 'Enter',
  Escape = 'Escape',
  KeyY = 'KeyY',
  KeyN = 'KeyN'
}

export enum Statuses {
  ACTIVE = 'ACTV',
  IN_ACTIVE = 'INAC',
  EXPIRED = 'EXPR'
}

export const pageLimitOptions = [
  { value: 10 },
  { value: 25 },
  { value: 50 },
  { value: 100 },
];
export const visibleOptions = [
  { value: 1 },
  { value: 3 },
  { value: 5 },
  { value: 10 },
];
