import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AppConfig } from '../../_config/app-portal.config';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '..';
import { retry } from 'rxjs/operators';
import { SettingsService } from './settings.service';
// key that is used to access the data in local storageconst STORAGE_KEY = 'local_todolist';
@Injectable()
export class LocalStorageService {
  public apiEndPoint: string; // = environment.SPECTRUM_API;
  anotherTodolist = [];
  STORAGE_KEY = AppConfig.MODULE_ID;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService, 
    private http: HttpClient,
    private settingsService: SettingsService
) { 
  this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
}

  public storeOnLocalStorage(dataForStore: string, key?: string): void {
    const storeKey = key || this.STORAGE_KEY;

    // insert updated array to local storage
    this.storage.set(storeKey, dataForStore);
  }
  getFromLocalStorage(key: string): { key: '' } {
    const storeKey = key || this.STORAGE_KEY;
    var token = this.storage.get(storeKey);
    if (token)
      return token;
    return { key: '' };
  }

  loadToken() {
    this.storage.clear();
    return this.http.post<ApiResponse>(`${this.apiEndPoint}/load-module/spectrum`, {}).subscribe((res) => {
      if (res.success && res.data) {
        this.storeOnLocalStorage(res.data, this.STORAGE_KEY);
      }
    });
  }

  getToken() {




  }



}
