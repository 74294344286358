import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/core/spectrum/_services/settings.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  apiEndPoint: string;
  key = environment.API_KEY as unknown as {value: ''};

  fileFormat = "spectrum";
  
  constructor(
    private settingsService: SettingsService,
    public dialogRef: MatDialogRef<DownloadComponent>
  ) {
    this.apiEndPoint = this.settingsService.settings.SPECTRUM_API;
   }

  ngOnInit() {
  }
  
  downalodFile() {
    let url: string = `${this.apiEndPoint}/${this.key}/`;
    if (this.fileFormat == "spectrum") {
      url += "write-json-file";
    } else {
      url += "write-strorm-file";
    }
    window.open(url, "_blank");
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close(null);
  }
}
